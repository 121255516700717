import { ConfigProvider, Layout, Space, Typography } from "antd"
import React from "react"
import { KernelLogoPlain, LogoSeparator } from "../../components/Navigation";
import Flow from "../Flow";
import moment from "moment";

const { Text, Title } = Typography

const OfflineFlowUI: React.FC = () => {
    const isSynapse = !!process.env.REACT_APP_LOCAL_FLOW_UI_TOKEN

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontSize: 16,
                },
            }}
        >
            <Layout className='layout'>
                <Layout.Header className="site-layout-header">
                    <Space>
                        <KernelLogoPlain />
                        <LogoSeparator />
                        <Title level={4} style={{ margin: 0, marginLeft: 15, fontFamily: 'Museo' }}>{isSynapse ? "Synapse" : "Offline"} Flow UI</Title>
                    </Space>
                </Layout.Header>
                <Layout.Content>
                    <ConfigProvider
                        theme={{
                            token: {
                                fontSize: 14,
                            },
                        }}
                    >
                        <div style={{ padding: 20 }}>
                            <Flow
                                isClinic={false}
                                isOffline={true}
                                isSynapse={isSynapse}
                                kortex_token={process.env.REACT_APP_LOCAL_FLOW_UI_TOKEN || "__KORTEX_TOKEN__"} // kortex fills this in
                                upload_token=""
                                tasks_url={process.env.REACT_APP_LOCAL_FLOW_UI_TOKEN ? "https://task.init.kernel.co" : "http://127.0.0.1:13254/offline/tasks/"}
                                data_streams={[]}
                                experiment_names={{}}
                                participants={[]}
                                study_id=""
                            />
                        </div>
                    </ConfigProvider>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}><Text type="secondary">©{moment().format('YYYY')} kernel</Text></Layout.Footer>
            </Layout>
        </ConfigProvider>
    )
}


export default OfflineFlowUI