import { ApiOutlined, BookOutlined, BulbOutlined, CameraOutlined, CheckCircleFilled, CompassOutlined, FunnelPlotOutlined, NodeIndexOutlined, RetweetOutlined, SlidersOutlined, StopOutlined, ThunderboltOutlined, WarningFilled } from "@ant-design/icons";
import * as Sentry from '@sentry/react';
import { Alert, Button, Card, Col, Collapse, QRCode, Row, Space, Spin, Typography, Progress, App } from "antd";
import moment, { Moment } from "moment";
import { default as React, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Prompt, generatePath, useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { DataStream, FlowUIMeta, KortexVersionResponse, useFlowUI, useKortexVersion, useUpdate } from "../../api";
import FlowLayout, { StatusValue, StatusValues } from "../../components/Flow/FlowLayout";
import RealTimeLayout from "../../components/Flow/RealTimeLayout";
import Record, { AreYouSure, ClinicRecordNavigationState, ExperimentEvent, TaskWarnings, UPLOAD_TARGET } from "../../components/Flow/Record";
import { useSelectedOrganizationContext } from "../../contexts/SelectedOrganization";
import { color } from "../../theme";
import { handleLog } from "../../utilities";
import CapturePhoto from "../../components/Flow/CapturePhoto";
import { NormalizedLandmark } from "@mediapipe/tasks-vision";

const { Title, Paragraph } = Typography
const { Panel } = Collapse

export const DEVICE_FLOW = "flow"
export const DEVICE_DANUBE = "danube"
export const DEVICE_KORTEX = "kortex"

const MAX_DANUBE_MODULES = 48
const EXPECTED_DANUBE_MODULES = 35
const DEFAULT_HIGH_PRIORITY_MODULES = new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 14, 15, 18, 19, 24, 25, 26, 27])
const DANUBE_BOOT_TIME_SECS = 88
const HUB_OUT_OF_SYNC_FAULT = "SYSTEM_OUT_OF_SYNC"
const MODULE_OUT_OF_SYNC_FAULT = "OUT_OF_SYNC"
const MODULE_OVER_TEMPERATURE_FAULT = "OVER_TEMPERATURE"
const MODULE_LASER_OVER_TEMPERATURE_FAULT = "LASER_OVER_TEMPERATURE"

const KORTEX_WS_URL = "ws://127.0.0.1:13254"
const MIN_SUPPORTED_KORTEX_VERSION_FOR_UPDATE = "12.0.0"

/**
 * Compares two version strings and returns true if v1 is greater than or equal to v2.
 * @param v1 The first version string.
 * @param v2 The second version string.
 * @returns True if v1 is greater than or equal to v2, false otherwise.
 */
const compareVersions = (v1: string, v2: string): boolean => {
  const [major1, minor1, patch1] = v1.split('.').map(Number);
  const [major2, minor2, patch2] = v2.split('.').map(Number);
  return major1 !== major2 ? major1 > major2 : minor1 !== minor2 ? minor1 > minor2 : patch1 >= patch2;
}

export type FlowState = "not-ready" | "ready" | "initializing" | "initialized" | "coupling" | "tuning" | "photo" | "data-on" | "data-off" | "faulted"
type FlowUsbState = "danube" | undefined

type DanubeVersion = {
  DanubeVersion: {
    major: number
    minor: number
    patch: number
    commit_hash: string // still coming in as bytes
    is_manufacturing: boolean
  }
}

type DanubeSerialNumbers = {
  DanubeSerialNumbers: {
    hub_serial_number: string,
    follower_serial_numbers: string[],
    module_serial_numbers: string[],
    asic_serial_numbers: number[][],
  }
}

type KortexError = {
  id: string
  msg: string
  error_code: number
}

export type CommandResponse<T> = {
  id: string,
  response_data: T
}

type DanubeSystemState = undefined | "BOOTING" | "SLEEPING" | "READY" | "DATA_GATHERING" | "FAULTED" // "AWAKE" is manufacturing only
export type FaultInformation = { hub: string[], followers: Record<number, string[]>, modules: Record<number, string[]> }

export const StudyFlowUI: React.FC = () => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>()
  const { data: flowUIMeta } = useFlowUI(organizationId, studyId)
  const [checkUpdate] = useUpdate()

  return flowUIMeta ? <Flow checkUpdate={checkUpdate} {...flowUIMeta} /> : <Spin />
}

export const LasersAreOn: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      {children}
      <Alert type="warning" showIcon icon={<WarningFilled />} message="LASERS ARE ON" />
    </div>
  ) 
}

const Flow: React.FC<{ checkUpdate?: ReturnType<typeof useUpdate>[0], isClinic?: boolean, isOffline?: boolean, isSynapse?: boolean } & FlowUIMeta> = ({ checkUpdate, isClinic = false, isOffline = false, isSynapse = false, kortex_token, upload_token, data_streams, tasks_url, experiment_names, participants, configuration, daq_configuration, syncbox_configuration }) => {
  const location = useLocation()
  const clinicDefaults: ClinicRecordNavigationState | undefined = location.state as ClinicRecordNavigationState | undefined;
  const flowStartup = isClinic && !clinicDefaults
  
  const [kernelTalk, setKernelTalk] = useState<typeof import('kernel-talk-web/kernel_talk_web')>()
  const [pendingSdkClient, setPendingSdkClient] = useState<import('kernel-talk-web/kernel_talk_web').SdkClient>()
  const [sdkClient, setSdkClient] = useState<import('kernel-talk-web/kernel_talk_web').SdkClient>()
  const [usbFlowConnected, setUsbFlowConnected] = useState<FlowUsbState>()
  const [stimboxConnected, setStimboxConnected] = useState<boolean>(false)
  const [stimboxInitialized, setStimboxInitialized] = useState<boolean>(false)
  const [daqConnected, setDaqConnected] = useState<boolean>(false)
  const lastSubscribeIds = useRef<Record<string, number | NodeJS.Timeout | null>>({})

  const [flowState, setFlowState] = useState<FlowState>("not-ready")
  const flowStateRef = useRef<FlowState>("not-ready") // used to read state within closures
  const [futureFlowState, setFutureFlowState] = useState<FlowState>()
  const [danubeSystemState, setDanubeSystemState] = useState<DanubeSystemState>()
  const [danubeBootingAt, setDanubeBootingAt] = useState<Moment>()
  const [danubeBootingSecondsPassed, setDanubeBootingSecondsPassed] = useState<number>(0)
  const [changingLaserState, setChangingLaserState] = useState<boolean>(false)
  const [availableModules, setAvailableModules] = useState<Set<number>>(new Set())
  const [faultedModules, setFaultedModules] = useState<Set<number>>(new Set())
  const [connectedDataStream, setConnectedDataStream] = useState<DataStream & {
    ws_url: string
    ws_token: string
    ws_sub_token: string
  }>()
  const [seenAllModules, setSeenAllModules] = useState<boolean>(false)
  const [lastExperimentEvent, setLastExperimentEvent] = useState<ExperimentEvent>()
  const [syncAlert, setSyncAlert] = useState<string>()
  const [couplingAlert, setCouplingAlert] = useState<boolean>(false)
  const [faultedError, setFaultedError] = useState<{ message: string, description?: React.ReactNode }>({ message: "An error occured" })
  const { refetch: refetchKortexVersion } = useKortexVersion()
  const [kortexVersion, setKortexVersion] = useState<KortexVersionResponse>()
  const [fwVersion, setFwVersion] = useState<DanubeVersion["DanubeVersion"]>()
  const [kortexAlert, setKortexAlert] = useState<string>()
  const [updatingSoftware, setUpdatingSoftware] = useState<boolean>(false)
  const [writerState, setWriterState] = useState<boolean>()
  const [hasHandshake, setHasHandshake] = useState<boolean>(false)
  const [recordExperimentAlert, setRecordExperimentAlert] = useState<string>()
  const [recordingStart, setRecordingStart] = useState<number | undefined>(undefined)
  const [manualCommand, setManualCommand] = useState<boolean>()
  const { message: messageApi } = App.useApp();
  const [participantPhoto, setParticipantPhoto] = useState<string>()
  const [participantPhotoLandmarks, setParticipantPhotoLandmarks] = useState<NormalizedLandmark[]>()
  const [bypassLaserOnPrompt, setBypassLaserOnPrompt] = useState<boolean>(false)

  useEffect(() => {
    if (danubeBootingAt) {
      const intervalId = setInterval(() => {
        const secondsPassed = Math.floor(moment().diff(danubeBootingAt) / 1000.0)
        setDanubeBootingSecondsPassed(secondsPassed)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [danubeBootingAt])

  const tryCancelSubscribe = useCallback((name: string) => {
    if (!sdkClient || !lastSubscribeIds.current[name]) return

    try {
      const id = lastSubscribeIds.current[name];
      handleLog("subscribe", `cancelling ${name} ${id as number}`)
      sdkClient.cancel(id)
    } catch (e) {
      handleLog("subscribe", `failed to cancel ${name}`, e)
    }
    lastSubscribeIds.current[name] = null
  }, [sdkClient, lastSubscribeIds])

  const subscribe = useCallback(async function subscribe<T>(
    device_name: string,
    field_urns: string[],
    rate_us: number,
    pre_subscribe: boolean,
    callback: (data: T) => void,
    no_virtual = false,
  ): Promise<number | null> { // returns id for unsubscribing
    if (!kernelTalk || !sdkClient) {
      return Promise.resolve(null)
    }

    handleLog("subscribe", `Subscribing to ${device_name}: ${field_urns.join(" ")}`)
    const ent = new kernelTalk.Event(
      device_name,
      field_urns,
      rate_us,
      pre_subscribe
    );

    const wrappedCallback = (data: T) => {
      try {
        callback(data)
      } catch (e) {
        handleLog("subscribe", `failed to callback ${device_name}: ${field_urns.join(" ")}`, e)
      }
    };

    if (no_virtual) {
      const id = await (sdkClient.subscribe(ent, wrappedCallback, false) as Promise<number>);
      handleLog("subscribe", `id: ${id}`)
      return id
    }

    const id = await (sdkClient.new_event(ent, wrappedCallback, false) as Promise<number>);
    handleLog("subscribe", `id: ${id}`)
    return id
  }, [sdkClient])

  const notifyError = useCallback((title: string, description?: React.ReactNode) => {
    setFaultedError({
      message: title,
      description,
    })

    setFlowState("faulted")
    setDanubeSystemState("FAULTED")
  }, [sdkClient]);
  
  useEffect(() => {
    void (async () => {
      handleLog("lib", "importing lib ...")
      setKernelTalk(await import('kernel-talk-web/kernel_talk_web'))
      handleLog("lib", "imported lib!")
    })()
  }, [])

  useEffect(() => {
    const debounce = setTimeout(() => setSdkClient(pendingSdkClient), 1000)
    return () => clearTimeout(debounce)
  }, [pendingSdkClient])

  const resetConnectionState = () => {
    setUsbFlowConnected(undefined)
    setDanubeSystemState(undefined)
    setFlowState("not-ready")
    setFutureFlowState(undefined)
  }

  useEffect(() => {
    if (!kernelTalk) return;
    if (kortexAlert) return resetConnectionState()

    let newSdkClient: import('kernel-talk-web/kernel_talk_web').SdkClient | undefined = undefined
    try {
      handleLog("sdkClient", `starting SDK Client ...`)
      newSdkClient = new kernelTalk.SdkClient(KORTEX_WS_URL, kortex_token)
      let connecting = false

      newSdkClient.when_connected(async () => {
        connecting = true
        handleLog("sdkClient", `SDK Client CONNECTED callback called`);

        const version = await refetchKortexVersion();
        if (!version) {
          setKortexAlert("Unable to get the Acquisition Driver version!")
          return
        }
        handleLog("sdkClient", `kortex version: ${version.kortex_version}`)
        Sentry.setTag("hostname", version.hostname)
        Sentry.setTag("kortex_version", version.kortex_version)
        setKortexVersion(version)

        resetConnectionState()
        if (connecting) setPendingSdkClient(newSdkClient)
      });
      newSdkClient.when_disconnected((closeEvent: CloseEvent) => {
        connecting = false
        handleLog("sdkClient", `SDK Client DISCONNECTED callback called`);
        
        resetConnectionState()
        setPendingSdkClient(undefined)

        if (closeEvent.reason === "UNAUTHORIZED") {
          setTimeout(() =>
            setKortexAlert("Connection not authorized: this is either due to a missing registration token (contact support) or an idle connection (refresh to reconnect).")
          , 0)
        }
      });

      newSdkClient.start()
      handleLog("sdkClient", `started SDK Client socket!`)
    } catch (e) {
      handleLog("sdkClient", `SDK Client socket error`, e)
    }

    return () => newSdkClient?.stop()
  }, [kernelTalk, kortexAlert])

  const subscribeToFaults = useCallback(async (uniqueSubscribeId: string) => {
    if (!sdkClient) return

    lastSubscribeIds.current[`fault_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/fault_info", (data: Map<"msg", FaultInformation>) => {
      const msg = data.get("msg")
      if (!msg) return
      
      if (msg.hub.filter(flag => flag !== HUB_OUT_OF_SYNC_FAULT).length > 0 || Object.values(msg.followers).some(flags => flags.length > 0)) {
        handleLog("faults", JSON.stringify(msg))
        setFaultedModules(new Set([-1]))
        return
      }
      
      const modules = new Set<number>()
      Object.entries(msg.modules).forEach(([moduleId, flags]) => {
        if (flags.filter(flag =>
          flag !== MODULE_OUT_OF_SYNC_FAULT &&
          flag !== MODULE_OVER_TEMPERATURE_FAULT &&
          flag !== MODULE_LASER_OVER_TEMPERATURE_FAULT
        ).length > 0) {
          handleLog("fault", `Module ${moduleId} has faults: ${flags.join(", ")}`, new Error("Faults Found"))
          modules.add(parseInt(moduleId))
        }
      })
      if (modules.size > 0) {
        handleLog("faults", JSON.stringify(msg))
        setFaultedModules(modules)
      }

      // handle out of sync faults separately
      if (msg.hub.find(flag => flag === HUB_OUT_OF_SYNC_FAULT)) {
        setSyncAlert("System not synchronized")
        // we're in a closure so use `flowStateRef` instead of `flowState`
        if (flowStateRef.current === "data-on") {
          // this is for the case where FW goes out of sync and transitions from DataGathering to Ready
          setFlowState("data-off")
        }
      } else {
        const outOfSyncModules: string[] = []
        Object.entries(msg.modules).forEach(([moduleId, flags]) => {
          if (flags.filter(flag => flag === MODULE_OUT_OF_SYNC_FAULT).length > 0) {
            outOfSyncModules.push(moduleId)
          }
        })
        if (outOfSyncModules.length > 0) {
          setSyncAlert(`Modules not synchronized: ${outOfSyncModules.join(", ")}`)
        } else {
          setSyncAlert(undefined)
        }
      }

      // handle over temp faults separately
      const overTempModules: string[] = []
      Object.entries(msg.modules).forEach(([moduleId, flags]) => {
        if (flags.filter(flag =>
          flag === MODULE_OVER_TEMPERATURE_FAULT ||
          flag === MODULE_LASER_OVER_TEMPERATURE_FAULT
        ).length > 0) {
          overTempModules.push(moduleId)
        }
      })
      if (overTempModules.length > 0) {
        Sentry.captureMessage(`Modules over temp: ${overTempModules.join(", ")}`)

        notifyError("System Overheating Detected",
          <ol>
            <li>Power down the Flow Headset</li>
            <li>Make sure the Flow Headset is on the headset stand</li>
            <li>Let the Flow Headset cool down for at least 30 minutes</li>
            <li>Start up the Flow Headset</li>
            <li>If this error persists, use the support button to file a ticket</li>
          </ol>
        )
      }
    }, false) as number;
  }, [!!sdkClient])

  const [priorFaultedModuleSize, setPriorFaultedModuleSize] = useState<number>(0)
  useEffect(() => {
    if (flowState === "not-ready") return
    if (faultedModules.size === priorFaultedModuleSize) return

    setPriorFaultedModuleSize(faultedModules.size)

    if (faultedModules.has(-1)) {
      void notifyError("Flow Headset has Hardware Faults");
      Sentry.captureMessage("Hardware Faults")
    } else {
      const modulesFormatted = [...faultedModules].join(", ")
      void notifyError(`Modules ${modulesFormatted} have Hardware Faults`);
      Sentry.captureMessage(`Module Faults: ${modulesFormatted}`)
    }
  }, [faultedModules, priorFaultedModuleSize, flowState])

  const subscribeToUsbFlow = useCallback(async (uniqueSubscribeId: string) => {
    if (!sdkClient) return

    const handleStateChange = (device: FlowUsbState, state: string) => {
      handleLog("danube", `state: ${state || 'N/A'}`)
      if (state === "CONNECTED") {
        let wasDisconnected = false
        setUsbFlowConnected(priorUsbFlowConnected => {
          wasDisconnected = !priorUsbFlowConnected
          return device
        })
        if (wasDisconnected) {
          handleLog("usb", `Flow ${device || 'unknown'} connected!`)
          setPriorFaultedModuleSize(0)
          setFaultedModules(new Set())
        }
      } else if (state === "DISCONNECTED") {
        resetConnectionState()
      }
    }

    lastSubscribeIds.current[`usb_danube_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/usb_status_danube", (data: Map<"msg", { state: string }>) => {
      const msg = data.get("msg")
      if (!msg) return

      const { state } = msg;
      handleStateChange("danube", state)
    }, false) as number;
 
    lastSubscribeIds.current[`danube_system_state_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/danube_system_state", (data:Map<"msg", DanubeSystemState>) => {
      // we're in a closure so use `flowStateRef` instead of `flowState`
      if (flowStateRef.current === "faulted" || danubeSystemState === "FAULTED") return
      
      const msg = data.get("msg")
      if (!msg) return

      handleLog("danube", `system state: ${msg}`)
      setDanubeSystemState(priorDanubeSystemState => {
        if (!priorDanubeSystemState) {
          if (msg === "BOOTING") {
            setFutureFlowState("not-ready")
          }
          else if (msg === "SLEEPING") {
            setFutureFlowState("ready")
          }
          else if (msg === "READY") {
            setFutureFlowState("initialized")
          }
          else if (msg === "DATA_GATHERING") {
            setFutureFlowState("coupling")
          }
        }
        setDanubeBootingSecondsPassed(0)
        if (msg === "BOOTING") {
          setDanubeBootingAt(moment())
        } else {
          setDanubeBootingAt(undefined)
        }
        if (msg === "FAULTED") {
          setFutureFlowState("faulted")
          setFlowState("faulted")
        }
        return msg;
      })
    }, false) as number;
  }, [!!sdkClient])

  useEffect(() => {
    if (!sdkClient) return

    let unmounted = false
    const uniqueSubscribeId = uuidv4()
    const undo = () => {
      tryCancelSubscribe(`task_${uniqueSubscribeId}`)
    }
    
    void (async () => {
      undo()

      const textDecoder = new TextDecoder()
      lastSubscribeIds.current[`task_${uniqueSubscribeId}`] = await subscribe<Map<string, unknown>>(
        "task",
        [
          "urn:kernel.com/field/task/generic/event",
        ],
        0,
        true,
        (data) => {
          const times = data.get("urn:kernel.com/field/timestamp") as BigInt64Array[]
          const events = data.get("urn:kernel.com/field/task/generic/event") as Uint8Array[]
          if (times && events && times.length > 0) {
            for (const event of events) {
              const eventName = textDecoder.decode(event)
              if (eventName === 'start_experiment' || eventName === 'end_experiment') {
                setLastExperimentEvent(eventName)
              }
            }
          }
        }
      );

      if (unmounted) undo()
    })()

    return () => {
      unmounted = true
      undo()
    }
  }, [!!sdkClient])

  useEffect(() => {
    if (!sdkClient) return

    let unmounted = false
    const uniqueSubscribeId = uuidv4()
    const undo = () => {
      tryCancelSubscribe(`fault_${uniqueSubscribeId}`)
      tryCancelSubscribe(`usb_danube_${uniqueSubscribeId}`)
      tryCancelSubscribe(`danube_system_state_${uniqueSubscribeId}`)
      tryCancelSubscribe(`writer_${uniqueSubscribeId}`)
      tryCancelSubscribe(`last_encryption_target_${uniqueSubscribeId}`)
      tryCancelSubscribe(`usb_stimbox_${uniqueSubscribeId}`)
      tryCancelSubscribe(`tcp_daq_${uniqueSubscribeId}`)
    }

    void (async () => {
      undo();

      handleLog("usb metadata", "subscribe")
      await subscribeToFaults(uniqueSubscribeId)
      await subscribeToUsbFlow(uniqueSubscribeId)

      lastSubscribeIds.current[`writer_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/writer", (data: Map<"msg", { state?: { Writing?: { WritingSession?: [string, boolean] } } | "NotWriting", error?: string }>) => {
        const msg = data.get("msg")
        if (!msg) return

        if (msg.error) Sentry.captureException(new Error(`Writer error: ${msg.error}`))
        else if (msg.state) setWriterState(msg.state === "NotWriting" ? false : msg.state.Writing?.WritingSession ? true : undefined)
      }, false) as number;

      lastSubscribeIds.current[`last_encryption_target_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/last_encryption_target", (data: Map<"msg", { target?: typeof UPLOAD_TARGET }>) => {
        const msg = data.get("msg")
        if (!msg) return

        setHasHandshake(msg.target === UPLOAD_TARGET)
      }, false) as number;
      
      lastSubscribeIds.current[`usb_stimbox_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/usb_status_stimbox", (data: Map<"msg", { state: string }>) => {
        const msg = data.get("msg")
        if (!msg) return
  
        const { state } = msg;
        setStimboxInitialized(false)
        setStimboxConnected(state === "CONNECTED")
      }, false) as number;

      try {
        lastSubscribeIds.current[`tcp_daq_${uniqueSubscribeId}`] = await sdkClient.metadata_subscribe("urn:kernel.com/module/metadata/tcp_daq", (data: Map<"msg", { server_state: string, num_connections: number }>) => {
          const msg = data.get("msg")
          if (!msg) return
    
          const { server_state, num_connections } = msg;
          setDaqConnected(server_state === "RUNNING" && num_connections > 0)
        }, false) as number;
      } catch (e) {
        if (e !== "Request failed: Reason(\"Unknown URN provided: urn:kernel.com/module/metadata/tcp_daq\")") {
          throw e
        }
      }

      if (unmounted) undo()
    })()

    return () => {
      unmounted = true
      undo()
    }
  }, [!!sdkClient])

  useEffect(() => {
    if (!sdkClient) return

    window.onSubmitZendeskTicket = () => void sdkClient.send_command(DEVICE_KORTEX, { EmitLogsToSentry: {} })
    return () => window.onSubmitZendeskTicket = undefined
  }, [!!sdkClient])

  const danubeSystemStateNotReady = usbFlowConnected === "danube" && (!danubeSystemState || danubeSystemState === 'BOOTING')
  const stimboxWarning = syncbox_configuration?.required && !stimboxInitialized
  const daqWarning = daq_configuration?.required && !daqConnected

  const updateSoftware = async (beforeInitialize: boolean) => {
    if (
      isOffline ||
      !kortexVersion ||
      !fwVersion ||
      !compareVersions(kortexVersion.kortex_version, MIN_SUPPORTED_KORTEX_VERSION_FOR_UPDATE) ||
      !(UPLOAD_TARGET === "NeuromeStaging" || UPLOAD_TARGET === "NeuromeProduction") ||
      !connectedDataStream ||
      !sdkClient ||
      !checkUpdate
    ) return

    // only run before initialize if either kortex or fw is old (otherwise breaks if you try to run post init because of get logs)
    // if both are old, we will run the update post init, if both are new, it's ok to run now because nothing should happen
    // TODO: in the future when all kortex/fw is updated we can remove the post init update and always update here
    const isNewKortex = compareVersions(kortexVersion.kortex_version, "12.2.2")
    const isNewFirmware = compareVersions(`${fwVersion.major}.${fwVersion.minor}.${fwVersion.patch}`, "2.23.3")
    if (beforeInitialize && !isNewKortex && !isNewFirmware) return

    const updateResponse = await checkUpdate({
      dataStreamId: connectedDataStream.id,
      systemType: kortexVersion.system_type,
      architecture: kortexVersion.system_arch,
      kortexVersion: kortexVersion.kortex_version,
      fwVersion: `${fwVersion.major}.${fwVersion.minor}.${fwVersion.patch}`,
    })

    if (updateResponse && (updateResponse.kortex_url || updateResponse.firmware_url)) {
      handleLog("connect", `Update available: kortex=${updateResponse.kortex_url ? 'true' : 'false'} fw=${updateResponse.firmware_url ? 'true' : 'false'}`)

      setUpdatingSoftware(true)

      try {
        await sdkClient.send_command(DEVICE_KORTEX, {
          Update: {
            ...updateResponse,
            handshake_target: UPLOAD_TARGET,
          },
        })
      } catch (e) {
        handleLog("connect", "Failed to update software", e)
        void notifyError("Error updating Software", "Please contact Kernel Support using the support button.")
      }

      setUpdatingSoftware(false)
    }
  }

  useEffect(() => {
    if (!sdkClient || !usbFlowConnected || danubeSystemStateNotReady || flowState !== 'not-ready' || priorFaultedModuleSize > 0) return

    void (async () => {
      try {
        handleLog("connect", "GetVersions")
        const res = await sdkClient.send_command(DEVICE_DANUBE, { GetVersions: {} }) as CommandResponse<DanubeVersion>
        const versions = res.response_data.DanubeVersion
        if (versions.is_manufacturing) {
          void notifyError("Manufacturing mode not supported on portal.", "Please switch to production firmware.")
          return
        }
        setFwVersion(versions)
        
        handleLog("connect", `version ${versions.major}.${versions.minor}.${versions.patch} ${versions.commit_hash}`)
        Sentry.setTag("fw_version", `${versions.major}.${versions.minor}.${versions.patch}`)

        handleLog("connect", "GetSerialNumbers")
        const res2 = await sdkClient.send_command(DEVICE_DANUBE, { GetSerialNumbers: {} }) as CommandResponse<DanubeSerialNumbers>
        const serialNumbers = res2.response_data.DanubeSerialNumbers
        handleLog("connect", `serial number ${serialNumbers.hub_serial_number}`)

        const resAvailableModules = new Set([...Array(MAX_DANUBE_MODULES).keys()].filter(i => !!serialNumbers.module_serial_numbers[i] && serialNumbers.module_serial_numbers[i] !== "NO_SERIAL_NUMBER"))
        const serialNumber = serialNumbers.hub_serial_number
        Sentry.setTag("hub_serial_number", serialNumber)

        const DANUBE_LIGHT_FILTER_MIN_CUTOFF_SN = 1500;
        const DANUBE_LIGHT_FILTER_MAX_CUTOFF_SN = 2000;
        const DANUBE_LIGHT_FILTER_MODULES = new Set([0, 2, 3, 6, 7])
        const moduleSerialNumbers = serialNumbers.module_serial_numbers.map((msn, idx) => ({
          sn: parseInt(msn.replace(/\D/g, ""), 10), // parse out just numeric portion
          id: idx
        })).filter(({ sn }) => !isNaN(sn)); // filter out anything that didn't parse such as empty string
        handleLog("connect", `module serial numbers: ${serialNumbers.module_serial_numbers.join(", ")}`)
        
        const lightFilterError = moduleSerialNumbers.some(({ id, sn }) => 
          DANUBE_LIGHT_FILTER_MODULES.has(id) &&
          sn >= DANUBE_LIGHT_FILTER_MIN_CUTOFF_SN &&
          sn < DANUBE_LIGHT_FILTER_MAX_CUTOFF_SN
        );
        if (process.env.REACT_APP_OFFLINE_FLOW_UI !== '1' && lightFilterError) {
          void notifyError("The headset has modules in an incorrect configuration.", "Please contact Kernel Support using the support button.")
          Sentry.captureMessage("Module light filter configuration error")
          return
        }

        if (!isOffline) {
          const dataStream = data_streams.find(dataStream => dataStream.flow_configuration?.serial_number === serialNumber);
          if (!dataStream) {
            void notifyError(`Serial number '${serialNumber}' not registered!`, "Please contact Kernel Support using the support button. Kernel needs to register this headset's serial number for your study organization.")
            Sentry.captureMessage(`Serial number '${serialNumber}' not registered`)
            return
          }

          Sentry.setTag("data_stream_id", dataStream.id)
          setConnectedDataStream(dataStream)

          if (UPLOAD_TARGET === "NeuromeStaging" || UPLOAD_TARGET === "NeuromeProduction") {
            await sdkClient.send_control({
              ConnectOutNeurome: {
                url: dataStream.ws_url,
                token: dataStream.ws_token,
                target: UPLOAD_TARGET,
                handshake_domain: new URL(process.env.REACT_APP_PORTAL_API_CONFIG_URL).hostname,
              }
            })
          }
        }

        handleLog("connect", `Available modules: ${[...resAvailableModules].join(', ')}`)
        setAvailableModules(resAvailableModules)

        setFlowState(futureFlowState || "ready")
        setFutureFlowState(undefined)
      } catch (e) {
        handleLog("connect", "error", e)
        void notifyError("Error communicating with Flow Headset")
      }
    })()

  }, [!!sdkClient, usbFlowConnected, danubeSystemState, flowState, futureFlowState, priorFaultedModuleSize === 0])

  useEffect(() => {
    flowStateRef.current = flowState
    if (flowState !== "ready") return
    handleLog("connect", "updateSoftware")
    void updateSoftware(true)
  }, [flowState])

  useEffect(() => {
    if (!sdkClient || !connectedDataStream || !stimboxConnected) return

    const commands = syncbox_configuration?.commands;
    if (!commands) {
      setStimboxInitialized(true)
      return
    }
    
    void (async () => {
      try {
        for (const command of commands) {
          await sdkClient.send_command('syncbox', command)
        }

        setStimboxInitialized(true)
      } catch (e) {
        handleLog("stimbox", "Failed to initialize stimbox", e)
      }
    })()

  }, [sdkClient, connectedDataStream, stimboxConnected])

  const moduleStatus = useMemo<StatusValues>(() => {
    const ret = new Map<number, StatusValue>()
    for (const n of Array(MAX_DANUBE_MODULES).keys()) ret.set(n, "missing")
    availableModules.forEach(n => ret.set(n, "available"))
    faultedModules.forEach(n => ret.set(n, "faulted"))
    return ret
  }, [availableModules, faultedModules])

  const initialize = async () => {
    if (!sdkClient) return

    try {
      setFlowState("initializing")

      handleLog("initialize", "start")
      await sdkClient.send_command(DEVICE_DANUBE, { Initialize: {
        target: UPLOAD_TARGET,
      } })
      handleLog("initialize", "stop")

      handleLog("initialize", "updateSoftware")
      await updateSoftware(false)

      setFlowState("initialized")
    } catch (e) {
      handleLog("initialize", "error", e)
      const msg = (e as KortexError)?.msg
      if (
        msg === "Timed out waiting for server handshake response" ||
        msg === "Internal error, response channel closed"
      ) {
        void notifyError("Error initializing the Flow Headset", (
          <ol>
            <li>Verify that your internet is connected and working</li>
            <li>Refresh this page and attempt to Initialize again</li>
            <li>If this error persists, use the support button to file a ticket</li>
          </ol>
        ))
      } else {
        void notifyError("Error initializing the Flow Headset")
      }
    }
  }

  const tune = async () => {
    if (!sdkClient) return

    try {
      setFlowState("tuning")
      handleLog("tune", "start")

      await sdkClient.send_command(DEVICE_DANUBE, { Tune: {}})
      
      setFlowState("data-on")

      handleLog("tune", "stop")
    } catch (e) {
      handleLog("tune", "error", e)
      void notifyError("Error tuning the Flow Headset")
    }
  }

  const turnOnLasers = async () => {
    if (!sdkClient) return
    setChangingLaserState(true)

    try {
      handleLog("startDataGathering", "start")

      await sdkClient.send_command(DEVICE_DANUBE, { StartDataGathering: {} })
      
      handleLog("startDataGathering", "stop")

      setCouplingAlert(false)
      setFlowState("coupling")
    } catch (e) {
      handleLog("startDataGathering", "error", e)
      void notifyError("Error Turning On Lasers")
    }

    setChangingLaserState(false)
  }

  const turnOffLasers = async () => {
    if (!sdkClient) return
    setChangingLaserState(true)

    try {
      handleLog("stopDataGathering", "start")

      await sdkClient.send_command(DEVICE_DANUBE, { StopDataGathering: {} })
      
      handleLog("stopDataGathering", "stop")

      setFlowState("data-off")
    } catch (e) {
      handleLog("stopDataGathering", "error", e)
      void notifyError("Error Turning Off Lasers")
    }

    setChangingLaserState(false)
  }

  useEffect(() => {
    if (!sdkClient) return
    void (async () => {
      try {
        await sdkClient.send_control({
          SetUploadToken: {
            target: UPLOAD_TARGET,
            token: upload_token,
          }
        })
      } catch (e) {
        handleLog("connect", "Failed to set upload token", e)
      }
    })()
  }, [sdkClient])

  useEffect(() => {
    if (!recordingStart && lastExperimentEvent === "start_experiment") {
      setRecordExperimentAlert(TaskWarnings.NOT_RECORDING_AND_TASK_STARTED)
    } else if (recordingStart && lastExperimentEvent === "start_experiment") {
      setRecordExperimentAlert(TaskWarnings.RECORDING_AND_TASK_NOT_ENDED)
    } else if (recordingStart && lastExperimentEvent === "end_experiment") {
      setRecordExperimentAlert(TaskWarnings.RECORDING_AND_TASK_ENDED)
    }
  }, [recordingStart, lastExperimentEvent])

  const [priorDataOn, setPriorDataOn] = useState<boolean>()
  useEffect(() => {
    void (async () => {
      if (manualCommand) return
      if (recordingStart && priorDataOn && flowState !== "data-on") {
        if (sdkClient) {
          try {
            setManualCommand(true)

            await sdkClient.send_control("StopWriting")
            setRecordingStart(undefined)
            handleLog("record", "stopped no data")
            void messageApi.warning("Recording stopped due to lasers turning off!")

            setTimeout(() => setManualCommand(false), 1000) // TODO: this could be better in leaving it true and when writerState changes, setting it to false but needs more testing
          }
          catch (e) {
            handleLog("record", "error stopping writing", e)
          }
        }
      }
      setPriorDataOn(flowState === "data-on")
    })()
  }, [manualCommand, recordingStart, priorDataOn, flowState === "data-on"])

  const expectedModuleCount = connectedDataStream?.flow_configuration?.expected_module_count || EXPECTED_DANUBE_MODULES

  const FlowModules = () => (
    <Card type="inner" title={`Found ${availableModules.size} Modules`} style={{ marginTop: 20 }}>
      <FlowLayout
        layout={<div dangerouslySetInnerHTML={{__html:connectedDataStream?.flow_configuration?.layout_svg || ''}}></div>}
        moduleStatus={moduleStatus}
      />
    </Card>
  )

  // may be undefined, only useful for generating links below in error case
  const { organization } = useSelectedOrganizationContext();

  return (
    <>
      <Prompt
        when={!bypassLaserOnPrompt && (!!recordingStart || flowState === "initializing" || flowState === "tuning" || flowState == "coupling" || flowState == "photo" || flowState === "data-on")}
        message={recordingStart ? 'The system is currently recording. Are you sure you want to leave?' : `The ${flowState === "data-on" ? "lasers are currently ON" : `headset is currently ${flowState}`}. Are you sure you want to leave?`}
      />
      <Row gutter={20} align="stretch" wrap={false}>
        <Col flex="auto">
          {!kernelTalk ? (
            <Alert
              type="info"
              showIcon
              icon={<BookOutlined />}
              message="Loading Libraries ..."
            />
          ) : !sdkClient ? (
            <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <FunnelPlotOutlined style={{ color: color.blue }} />
                Connecting to Acquisition Driver ...
              </Space>
            }>
              {kortexAlert && <Alert style={{ marginBottom: 20 }} showIcon type="error" message={kortexAlert} />}
              {!isClinic && !isOffline && !isSynapse && organization ? (
                <>
                  <Title level={5} style={{ marginTop: 0 }}>If unable to connect:</Title>
                  <ul>
                    <li>Is the Acquisition Driver (Kortex) installed? If not, see <Link to={generatePath('/organizations/:organizationId/#acquisition_driver', { organizationId: organization.id })}>Acquisition Driver: Installation</Link>.</li>
                    <li>Try restarting it: see <Link to={generatePath('/organizations/:organizationId/#acquisition_driver', { organizationId: organization.id })}>Acquisition Driver: Restarting</Link>.</li>
                    <li>Still not connecting? Use the support button to file a ticket.</li>
                  </ul>
                </>
              ) : (
                <>
                  <Title level={5} style={{ marginTop: 0 }}>Not connecting?</Title>
                  <ul>
                    <li>Try restarting your computer</li>
                    <li>Still not connecting? Use the support button to file a ticket.</li>
                  </ul>
                </>
              )}
            </Card>
          ) : (!usbFlowConnected || danubeSystemStateNotReady) ? (
            danubeSystemState === "BOOTING" ? (
              <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
                <Space direction="horizontal">
                  <ApiOutlined style={{ color: color.blue }} />
                  Flow Headset is starting up, please wait ...
                </Space>
              }>
                <Space direction="vertical" align="center" style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
                  <Progress
                    type="circle"
                    size={300}
                    percent={Math.min(100, Math.floor(100.0 * danubeBootingSecondsPassed / DANUBE_BOOT_TIME_SECS))}
                    format={() => danubeBootingSecondsPassed >= DANUBE_BOOT_TIME_SECS ? `Almost done...` : `${DANUBE_BOOT_TIME_SECS - danubeBootingSecondsPassed}s left`}
                  />
                </Space>
              </Card>
            ) : (
              <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
                <Space direction="horizontal">
                  <ApiOutlined style={{ color: color.blue }} />
                  Searching for Flow Headset ...
                </Space>
              }>
                <Title level={5} style={{ marginTop: 0 }}>If unable to connect:</Title>
                <ul>
                  <li>Is the Flow Headset powered on?</li>
                  <li>Is the USB cable plugged into the computer?</li>
                  <li>Is the PWR side of the USB cable plugged into the wall outlet power brick?</li>
                  <li>Is the FLOW side of the USB cable plugged into the headset?</li>
                  <li>Did you wait at least 30 seconds?</li>
                  <li>Still not connecting? use the support button to file a ticket</li>
                </ul>
              </Card>
            )
          ) : flowState === "faulted" ? (
            <Card headStyle={{ background: color.darkRed, borderColor: color.red }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <WarningFilled style={{ color: color.red }} />
                {faultedError.message}
              </Space>
            }>
              {faultedError.description || (
                <ol>
                  <li>Power down the Flow Headset</li>
                  <li>Make sure the Flow Headset is on the headset stand</li>
                  <li>Start up the Flow Headset</li>
                  <li>If this error persists, use the support button to file a ticket</li>
                </ol>
              )}
            </Card>
          ) : updatingSoftware ? (
            <Card headStyle={{ background: color.darkYellow, borderColor: color.yellow }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <RetweetOutlined style={{ color: color.yellow }} />
                Updating Software ...
              </Space>
            }>
              <Title level={5} style={{ marginTop: 0 }}>Please wait while the software is being updated.</Title>
            </Card>
          ) : flowState === "not-ready" ? (
            <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <NodeIndexOutlined style={{ color: color.blue }} />
                Gathering information ...
              </Space>
            }>
              <Title level={5} style={{ marginTop: 0 }}>Please wait until the headset status is determined.</Title>
            </Card>
          ) : flowState === "ready" ? (
            <Card headStyle={{ background: color.darkGreen, borderColor: color.green }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <CheckCircleFilled style={{ color: color.green }} />
                Connected to Flow
              </Space>
            }>
              <Row>
                <Col span={18}>
                  <Title level={5} style={{ marginTop: 0 }}>Please place the headset on the headset stand and turn off the lights.</Title>
                  <Paragraph>If the headset stand has locking clips, please make sure the headset is locked and tightened in the back.</Paragraph>
                </Col>
                <Col span={6}>
                  <Space direction="vertical" align="end" style={{ width: "100% "}}>
                    <Button icon={<BulbOutlined />} size="large" onClick={initialize} type="primary">Initialize</Button>
                  </Space>
                </Col>
              </Row>
              <FlowModules />
            </Card>
          ) : flowState === "initializing" ? (
            <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
              <LasersAreOn>
                <Space direction="horizontal">
                  <BulbOutlined style={{ color: color.blue }} />
                  Initializing ...
                </Space>
              </LasersAreOn>
            }>
              <Title level={5} style={{ marginTop: 0 }}>Please wait while the headset initializes.</Title>
            </Card>
          ) : flowState === "initialized" ? (
            <Card headStyle={{ background: color.darkGreen, borderColor: color.green }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <CompassOutlined style={{ color: color.green }} />
                Initialized!
              </Space>
            }>
              {!flowStartup && (
                <Row>
                  <Col span={18}>
                    <Title level={5} style={{ marginTop: 0 }}>Please place the headset on the Participant&apos;s head.</Title>
                  </Col>
                  <Col span={6}>
                    <Space direction="vertical" align="end" style={{ width: "100% "}}>
                      <AreYouSure
                        title={"Are you sure?"}
                        description={`Expecting ${expectedModuleCount} modules but headset only has ${availableModules.size}`}
                        bypass={availableModules.size >= expectedModuleCount}
                        onConfirm={turnOnLasers}
                        buttonText="Turn Lasers ON"
                        buttonProps={{
                          size: "large",
                          icon: <ThunderboltOutlined />,
                          type: "primary",
                          danger: true
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
              )}
              <FlowModules />
            </Card>
          ) : flowState === "tuning" ? (
            <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
              <LasersAreOn>
                <Space direction="horizontal">
                  <SlidersOutlined style={{ color: color.blue }} />
                  Tuning Lasers ...
                </Space>
              </LasersAreOn>
            }>
              <Title level={5} style={{ marginTop: 0 }}>Please stay still and refrain from speaking for 15-20 seconds.</Title>
            </Card>
          ) : flowState === "photo" ? (
            <Card headStyle={{ background: color.darkBlue, borderColor: color.blue }} bodyStyle={{ background: color.darkGray }} title={
              <LasersAreOn>
                <Space direction="horizontal">
                  <CameraOutlined style={{ color: color.blue }} />
                  Capture Photo
                </Space>
              </LasersAreOn>
            }>
              <Row>
                <Col span={18}>
                  <CapturePhoto savePhoto={(photo, landmarks) => {
                    setParticipantPhoto(photo)
                    setParticipantPhotoLandmarks(landmarks)
                  }} />
                </Col>
                <Col span={6}>
                  <Space direction="vertical" align="end" style={{ width: "100% "}}>
                    <AreYouSure
                      title={"Are you sure?"}
                      description={"No Photo Captured"}
                      bypass={!!participantPhoto}
                      onConfirm={tune}
                      buttonText="Continue"
                      buttonProps={{
                        size: "large",
                        icon: <ThunderboltOutlined />,
                        type: "primary",
                      }}
                    />
                    <Button size="large" disabled={changingLaserState} icon={<StopOutlined />} onClick={turnOffLasers} type="primary" danger>Turn Lasers OFF</Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          ) : flowState === "data-off" ? (
            <Card headStyle={{ background: color.darkGreen, borderColor: color.green }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <StopOutlined style={{ color: color.green }} />
                LASERS ARE OFF
              </Space>
            }>
              <Row>
                <Col span={18}>
                  <FlowModules />
                </Col>
                <Col span={6}>
                  <Space direction="vertical" align="end" style={{ width: "100% "}}>
                    <Button size="large" disabled={changingLaserState} icon={<ThunderboltOutlined />} onClick={turnOnLasers} type="primary" danger>Turn lasers ON</Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          ) : flowState === "coupling" ? (
            <Card headStyle={{ background: color.darkYellow, borderColor: color.yellow }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <WarningFilled style={{ color: color.yellow }} />
                LASERS ARE ON
              </Space>
            }>
              {flowStartup ? (
                <Button size="large" disabled={changingLaserState} icon={<StopOutlined />} onClick={turnOffLasers} type="primary" danger>Turn Lasers OFF</Button>
              ) : (
                <>
                  <Row>
                    <Col span={18}>
                      <Title level={5} style={{ marginTop: 0 }}>Turn off the lights and make sure there is no ambient light.</Title>
                      <Paragraph>Gently manipulate or wiggle the headset to help the lightpipes &quot;comb&quot; through the participant&apos;s hair and make better contact with the scalp.</Paragraph>
                    </Col>
                    <Col span={6}>
                      <Space direction="vertical" align="end" style={{ width: "100% "}}>
                        <AreYouSure
                          title={"Are you sure?"}
                          description={
                            !seenAllModules ? `Not receiving data from all ${availableModules.size} modules` :
                            couplingAlert ? "High-priority modules do not have high signal" :
                            ""
                          }
                          bypass={seenAllModules && !couplingAlert}
                          onConfirm={configuration?.participant_photo_required ? () => setFlowState("photo") : tune}
                          buttonText="Continue"
                          buttonProps={{
                            size: "large",
                            icon: <SlidersOutlined />,
                            type: "primary",
                          }}
                        />
                        <Button size="large" disabled={changingLaserState} icon={<StopOutlined />} onClick={turnOffLasers} type="primary" danger>Turn Lasers OFF</Button>
                      </Space>
                    </Col>
                  </Row>
                  <RealTimeLayout
                    kernelTalk={kernelTalk}
                    sdkClient={sdkClient}
                    flowState={flowState}
                    availableModules={availableModules}
                    highPriorityModules={new Set(connectedDataStream?.flow_configuration?.configuration_patch?.high_priority_modules || DEFAULT_HIGH_PRIORITY_MODULES)}
                    lastSubscribeIds={lastSubscribeIds}
                    subscribe={subscribe}
                    tryCancelSubscribe={tryCancelSubscribe}
                    layout={<div dangerouslySetInnerHTML={{__html:connectedDataStream?.flow_configuration?.layout_svg || ''}}></div>}
                    onDataReceived={setSeenAllModules}
                    onAggregate={(good) => setCouplingAlert(!good)}
                  />
                </>
              )}
            </Card>
          ) : ( /* data-on */
            <Card headStyle={{ background: color.darkYellow, borderColor: color.yellow }} bodyStyle={{ background: color.darkGray }} title={
              <Space direction="horizontal">
                <WarningFilled style={{ color: color.yellow }} />
                LASERS ARE ON
              </Space>
            }>
              <Row>
                <Col span={18}>
                  {!flowStartup && (
                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                      {availableModules.size < expectedModuleCount && <Alert type="warning" showIcon message={`Expecting ${expectedModuleCount} modules but headset only has ${availableModules.size}`} />}
                      {!seenAllModules && <Alert type="warning" showIcon message={`Not receiving data from all ${availableModules.size} modules`} />}
                      {!!couplingAlert && <Alert type="warning" showIcon message={`High-priority modules do not have high signal`} />}
                      {!!syncAlert && <Alert type="warning" showIcon message={syncAlert} />}
                      {!hasHandshake && <Alert type="error" showIcon message="Headset not initialized! Please power cycle and re-initialize the headset." />}
                      {recordExperimentAlert && <Alert type="warning" showIcon message={recordExperimentAlert} />}
                      {stimboxWarning && <Alert type="warning" showIcon message="Syncbox required but not connected" />}
                      {daqWarning && <Alert type="warning" showIcon message="DAQ required but not connected" />}
                      <Record
                        sdkClient={sdkClient}
                        isOffline={isOffline}
                        isSynapse={isSynapse}
                        dataStreamId={connectedDataStream?.id}
                        experimentNames={experiment_names}
                        participants={participants}
                        clearExperimentEvent={() => setLastExperimentEvent(undefined)}
                        tasksUrl={`${tasks_url}?kortexToken=${kortex_token}${clinicDefaults?.meta?.experiment_name ? `&task=${encodeURI(clinicDefaults.meta.experiment_name)}&sessionNumber=${encodeURI(clinicDefaults.meta.session_number || '1')}` : ''}`}
                        warningsExist={
                          /* this should match above alerts */
                          (availableModules.size < expectedModuleCount) ||
                          !seenAllModules ||
                          !!couplingAlert ||
                          !!syncAlert ||
                          !hasHandshake ||
                          !!recordExperimentAlert ||
                          !!stimboxWarning ||
                          !!daqWarning
                        }
                        writerState={writerState}
                        setWriterState={setWriterState}
                        hasHandshake={hasHandshake}
                        recordExperimentAlert={recordExperimentAlert}
                        setRecordExperimentAlert={setRecordExperimentAlert}
                        recordingStart={recordingStart}
                        setRecordingStart={setRecordingStart}
                        manualCommand={manualCommand}
                        setManualCommand={setManualCommand}
                        participantPhotoRequired={!!configuration?.participant_photo_required}
                        participantPhoto={participantPhoto}
                        participantPhotoLandmarks={participantPhotoLandmarks}
                        setBypassLaserOnPrompt={setBypassLaserOnPrompt}
                        isClinic={isClinic}
                      />
                      {!isClinic && connectedDataStream && (
                        <Collapse>
                          <Panel header="Remote Monitoring" key="1">
                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                              <QRCode value={`https://${window.location.hostname}/websocket/data_stream_subscribe?websocketUrl=${encodeURIComponent(`${connectedDataStream.ws_url}?authorization=${encodeURIComponent(connectedDataStream.ws_sub_token)}`)}`} size={300} />
                            </div>
                          </Panel>
                        </Collapse>
                      )}
                    </Space>
                  )}
                </Col>
                <Col span={6}>
                  <Space direction="vertical" align="end" style={{ width: "100% "}}>
                    <Button size="large" disabled={changingLaserState} icon={<StopOutlined />} onClick={turnOffLasers} type="primary" danger>Turn Lasers OFF</Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </>
  )
}

export default Flow
