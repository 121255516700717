import { Button, Card, Checkbox, DatePicker, Input, InputNumber, Progress, Radio, Slider, Space, Typography } from "antd"
import React, { ReactNode, useEffect, useMemo, useState } from "react"
import { DataStreamSurveyStepQuestion, DataStreamSurveyStepQuestionType } from "../../../api"
import { isEmptyValue } from "../../../utilities"
import { SurveyStepForm, SurveyStepEvent, SurveyStepFormAnswerValue } from "../types"
import MarkdownPreview from '@uiw/react-markdown-preview';
import styles from './styles.module.css'

// TODO: we're customizing `dayjs` here but antd should really do it for us
// https://github.com/react-component/picker/issues/123#issuecomment-728755491
// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { Title } = Typography;

type TakerProps = {
    questions: DataStreamSurveyStepQuestion[],
    onChange: (done: boolean, form: SurveyStepForm) => void,
    extra?: React.ReactNode,
    allQuestionsRequired?: boolean,
    submitButton?: {
        text: string,
        onClick: () => void,
        loading: boolean,
    }
}

const isQuestionComplete = (type: DataStreamSurveyStepQuestionType, newValue: SurveyStepFormAnswerValue, prefersNotToAnswer?: boolean) => !isEmptyValue(newValue) || prefersNotToAnswer || type === DataStreamSurveyStepQuestionType.Instruction

const Taker: React.FC<TakerProps> = ({ questions, onChange, extra, allQuestionsRequired, submitButton }) => {
    const [form, setForm] = useState<SurveyStepForm>({})
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>();

    const done = useMemo(() => questions.every(({ id, type }) => {
        const { value, prefersNotToAnswer } = form[id] || {}
        return isQuestionComplete(type, value, prefersNotToAnswer)
    }), [JSON.stringify(form)])

    useEffect(() => {
        onChange(done, form)
    }, [done, JSON.stringify(form)])
    
    return questions && (
        <Stepper
            extra={extra}
            isNextDisabled={isNextDisabled}
            done={done}
            submitButton={submitButton}
        >
            {questions.map(question => 
                <Question
                    form={form}
                    setForm={setForm}
                    key={question.id} 
                    question={question}
                    onAnswer={(hasAnswer) => setIsNextDisabled(!hasAnswer)}
                    required={!!allQuestionsRequired}
                />
            )}
        </Stepper>
    )
}

type StepperProps = {
    children: JSX.Element[],
    extra?: React.ReactNode,
    isNextDisabled?: boolean,
    submitButton?: TakerProps['submitButton'],
    done: boolean,
}

const Stepper: React.FC<StepperProps> = ({ children, extra, isNextDisabled, submitButton, done }) => {
    const [currStep, setStep] = useState<number>(0);
    const numSteps = children?.length ?? 0;

    const onPreviousStep = () => {
        if (currStep === 0) return;
        setStep(currStep - 1);
    }

    const onNextStep = () => {
        if (currStep === (numSteps - 1)) return;
        setStep(currStep + 1);
    }

    return (
        <Card
            title={<Progress steps={children.length} percent={Math.floor((currStep + 1) / numSteps * 100)} success={{ percent: 0 }} size="small" format={() => `Page ${currStep + 1} of ${numSteps}`} />}
            extra={extra}
            actions={[
                <Button key="previous" size="large" disabled={currStep <= 0} onClick={onPreviousStep}>Previous</Button>,
                currStep === numSteps - 1 && submitButton ? (
                    <Button key="submit" size="large" onClick={submitButton.onClick} loading={submitButton.loading} disabled={isNextDisabled || !done} type="primary">{submitButton.text}</Button>
                ) : (
                    <Button key="next" size="large" disabled={currStep >= numSteps - 1 || isNextDisabled} onClick={onNextStep}>Next</Button>
                ),
            ]}
        >
            {children && children[currStep]}
        </Card>
    )
}

type QuestionProps = {
    form: SurveyStepForm,
    setForm: (_: (_: SurveyStepForm) => SurveyStepForm) => void,
    question: DataStreamSurveyStepQuestion,
    onAnswer: (hasAnswer: boolean) => void,
    required: boolean,
}

const Question: React.FC<QuestionProps> = ({ form, setForm, question, onAnswer, required }) => {
    const { id, label, title, type, range, choices } = question
    const { prefersNotToAnswer, value } = form[id] || {};
    const disabled = !!prefersNotToAnswer;

    const labelAndTitle = `${label || ''} ${title || ''}`

    useEffect(() => {
        // When the form loads, enforce question completion to proceed
        // TODO: In the future we will need to specify required questions
        onAnswer?.(isQuestionComplete(type, value, prefersNotToAnswer));
    }, [value]);

    const onQuestionAnswer = (questionId: string, value: SurveyStepFormAnswerValue = null, prefersNotToAnswer?: boolean) => {
        setForm(form => ({
                ...form,
                [questionId]: {
                    value,
                    events: {
                        ...form[questionId]?.events,
                        [SurveyStepEvent.SurveyQuestionAnswered]: (new Date()).toISOString()
                    },
                    prefersNotToAnswer
                },
            })
        )
        // Use answer state within function scope (most up-to-date)
        onAnswer?.(isQuestionComplete(type, value, prefersNotToAnswer));
    }

    const isUserDefinedValue = (value?: string) => {
        return value === 'other' || (typeof value === 'string' && value?.startsWith('other-'));
    }

    return (
        <Space direction="vertical" style={{ maxWidth: 800 }}>
            <MarkdownPreview source={labelAndTitle} />

            {(() => {
                switch (type) {
                    case DataStreamSurveyStepQuestionType.Range: {
                        return (
                            <Space direction="horizontal">
                                {range?.minimumLabel}
                                <Slider
                                    min={range?.minimumValue}
                                    max={range?.maximumValue}
                                    step={range?.step}
                                    marks={(range?.ticks || []).reduce((a, c) => {
                                        if (range && range.minimumValue && range.maximumValue && range.minimumValue <= c && c <= range.maximumValue) {
                                            a[c] = <>|</>
                                        }
                                        return a;
                                    }, {} as Record<number, ReactNode>)}
                                    onChange={onQuestionAnswer.bind(null, id)}
                                    disabled={disabled}
                                    value={(typeof(value) === 'undefined' ? null : value) as number}
                                    style={{ width: 600 }}
                                />
                                {range?.maximumLabel}
                            </Space>
                        );
                    }
                    case DataStreamSurveyStepQuestionType.Input:
                        return (
                            <Input
                                size="large"
                                multiple={true}
                                onChange={e => onQuestionAnswer(id, e.target.value)}
                                disabled={disabled}
                                value={value as string}
                                placeholder='Your answer'
                            />
                        );
                    case DataStreamSurveyStepQuestionType.Number:
                        return (
                            <Space direction="horizontal" align="center">
                                <Title level={4} style={{ margin: 0 }}>#</Title>
                                <InputNumber
                                    size="large"
                                    onChange={onQuestionAnswer.bind(null, id)}
                                    disabled={disabled}
                                    value={value as string}
                                    placeholder='Your answer'
                                />
                            </Space>
                        );
                    case DataStreamSurveyStepQuestionType.Time:
                        return (
                            <DatePicker
                                showTime
                                size="large"
                                onChange={d => onQuestionAnswer(id, d?.unix())}
                                disabled={disabled}
                                value={isEmptyValue(value) ? undefined : dayjs.unix(value as number)}
                            />
                        );
                    case DataStreamSurveyStepQuestionType.Day:
                        return (
                            <DatePicker
                                size="large"
                                onChange={(_d, ds) => onQuestionAnswer(id, ds)}
                                disabled={disabled}
                                value={isEmptyValue(value) ? undefined : dayjs(value as string)}
                            />
                        );
                    case DataStreamSurveyStepQuestionType.Month:
                        return (
                            <DatePicker
                                picker="month"
                                size="large"
                                onChange={(_d, ds) => onQuestionAnswer(id, ds)}
                                disabled={disabled}
                                value={isEmptyValue(value) ? undefined : dayjs(value as string)}
                            />
                        );
                    case DataStreamSurveyStepQuestionType.Year:
                        return (
                            <DatePicker
                                picker="year"
                                size="large"
                                onChange={(_d, ds) => onQuestionAnswer(id, ds)}
                                disabled={disabled}
                                value={isEmptyValue(value) ? undefined : dayjs(value as string)}
                            />
                        );
                    case DataStreamSurveyStepQuestionType.MultipleSelect: {
                        return (
                            <Space direction="vertical">
                                <Checkbox.Group
                                    className={styles.CustomCheckboxGroup}
                                    options={choices}
                                    disabled={disabled}
                                    onChange={values => onQuestionAnswer(id, values.join(","))}
                                    value={(value as string)?.split?.(',')}
                                />
                            </Space>
                        );
                    }
                    case DataStreamSurveyStepQuestionType.MultipleChoice:
                    case DataStreamSurveyStepQuestionType.MultipleChoiceRadio: {
                        return (<>
                            <Radio.Group size="large" onChange={e => onQuestionAnswer(id, e.target.value)} value={value}>
                                <Space direction="vertical">
                                    {choices?.map(choice => (
                                        <Radio key={choice.value} value={choice.value}>{choice.label}</Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                            {isUserDefinedValue(value as string) &&
                                <Input
                                    size="large"
                                    multiple={true}
                                    onChange={e =>
                                        onQuestionAnswer(id, `other-${e.target.value}`)
                                    }
                                    placeholder='Your answer'
                                    value={(value as string)?.split('other-')[1]}
                                />
                            }
                        </>)
                    }
                    case DataStreamSurveyStepQuestionType.Instruction:
                    default:
                        return null;
                }
            })()}

            {type !== DataStreamSurveyStepQuestionType.Instruction && !required && (
                <Checkbox
                    style={{ marginTop: 20, fontSize: 14 }}
                    checked={disabled}
                    onChange={() =>
                        onQuestionAnswer(
                            id,
                            undefined, // Unset value if prefer not to answer check changed
                            !prefersNotToAnswer
                        )
                    }
                >Prefer Not to Answer</Checkbox>
            )}
        </Space>
    )
}

export default Taker